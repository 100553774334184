import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from '../images/logo.png'; // Ensure this path is correct

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    const isHomePage = location.pathname === '/';

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const navItems = [
        { name: 'Home', path: '/' },
        { name: 'Services', path: '/services' },
        { name: 'About', path: '/about' },
        { name: 'Contact', path: '/contact' },

    ];

    return (
        <motion.header
            className={`fixed w-full z-50 transition-all duration-300 ${
                isHomePage
                    ? isScrolled ? 'bg-blue-900 shadow-lg' : 'bg-transparent'
                    : 'bg-blue-900 shadow-lg'
            }`}
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="container mx-auto px-4 py-4">
                <div className="flex justify-between items-center">
                    <Link to="/" className="flex items-center">
                        <img src={logo} alt="THETA AI Logo" className="h-10 w-auto" />
                    </Link>
                    <nav className="hidden lg:block">
                        <ul className="flex space-x-8 items-center">
                            {navItems.map((item) => (
                                <li key={item.name}>
                                    <Link
                                        to={item.path}
                                        className={`text-white hover:text-yellow-300 transition duration-300 ${
                                            location.pathname === item.path ? 'border-b-2 border-yellow-300' : ''
                                        }`}
                                    >
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                            <li>
                                <Link
                                    to="/contact"
                                    className="bg-yellow-300 text-blue-900 px-6 py-2 rounded-full hover:bg-yellow-400 transition duration-300 font-semibold"
                                >
                                    Inquire Now
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="lg:hidden text-white focus:outline-none"
                    >
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
            </div>
            <motion.div
                className={`lg:hidden fixed inset-0 z-40 bg-blue-900 transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}
                initial={false}
                animate={{ x: isMenuOpen ? 0 : '-100%' }}
                transition={{ duration: 0.3 }}
            >
                <div className="flex justify-end p-4">
                    <button onClick={() => setIsMenuOpen(false)} className="text-white">
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <nav className="px-4 py-2">
                    {navItems.map((item) => (
                        <Link
                            key={item.name}
                            to={item.path}
                            className={`block py-2 text-white hover:text-yellow-300 transition duration-300 ${
                                location.pathname === item.path ? 'text-yellow-300' : ''
                            }`}
                            onClick={() => setIsMenuOpen(false)}
                        >
                            {item.name}
                        </Link>
                    ))}
                    <Link
                        to="/contact"
                        className="block mt-4 text-center bg-yellow-300 text-blue-900 px-6 py-2 rounded-full hover:bg-yellow-400 transition duration-300 font-semibold"
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Inquire Now
                    </Link>
                </nav>
            </motion.div>
        </motion.header>
    );
}

export default Header;