import React, { useState, useEffect } from 'react';
import { databases, DEMO_DATABASE_ID, DEMO_COLLECTION_ID } from '../config/appwrite';
import { ID, Query } from 'appwrite';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Trash2, Edit, Plus, X } from 'lucide-react';

const initialDemoConfig = {
    name: '',
    description: '',
    headerTitle: '',
    welcomeMessage: '',
    primaryColor: '#10B981',
    secondaryColor: '#059669',
    agentName: '',
    contextPrompt: '',
    conversationStarters: [{ icon: '💬', text: '', category: '' }]
};

function DemoCreation() {
    const [isLoading, setIsLoading] = useState(false);
    const [demoConfig, setDemoConfig] = useState(initialDemoConfig);
    const [demos, setDemos] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchDemos();
    }, []);

    const fetchDemos = async () => {
        try {
            const response = await databases.listDocuments(
                DEMO_DATABASE_ID,
                DEMO_COLLECTION_ID,
                [Query.orderDesc('$createdAt')]
            );
            setDemos(response.documents);
        } catch (error) {
            toast.error('Failed to fetch demos');
            console.error('Fetch error:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDemoConfig(prev => ({ ...prev, [name]: value }));
    };

    const addConversationStarter = () => {
        setDemoConfig(prev => ({
            ...prev,
            conversationStarters: [
                ...prev.conversationStarters,
                { icon: '💬', text: '', category: '' }
            ]
        }));
    };

    const updateConversationStarter = (index, field, value) => {
        const updatedStarters = [...demoConfig.conversationStarters];
        updatedStarters[index] = { ...updatedStarters[index], [field]: value };
        setDemoConfig(prev => ({ ...prev, conversationStarters: updatedStarters }));
    };

    const removeConversationStarter = (index) => {
        setDemoConfig(prev => ({
            ...prev,
            conversationStarters: prev.conversationStarters.filter((_, i) => i !== index)
        }));
    };

    const validateConfig = () => {
        const requiredFields = ['name', 'headerTitle', 'welcomeMessage', 'agentName'];
        const missingFields = requiredFields.filter(field => !demoConfig[field].trim());

        if (missingFields.length > 0) {
            throw new Error(`Required fields missing: ${missingFields.join(', ')}`);
        }

        if (demoConfig.conversationStarters.length === 0) {
            throw new Error('At least one conversation starter is required');
        }
    };

    const deleteDemo = async (demoId) => {
        if (window.confirm('Are you sure you want to delete this demo?')) {
            try {
                await databases.deleteDocument(
                    DEMO_DATABASE_ID,
                    DEMO_COLLECTION_ID,
                    demoId
                );
                toast.success('Demo deleted successfully');
                fetchDemos();
            } catch (error) {
                toast.error('Failed to delete demo');
                console.error('Delete error:', error);
            }
        }
    };

    const saveDemoConfig = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            validateConfig();

            const configToSave = {
                ...demoConfig,
                conversationStarters: JSON.stringify(demoConfig.conversationStarters)
            };

            const response = await databases.createDocument(
                DEMO_DATABASE_ID,
                DEMO_COLLECTION_ID,
                ID.unique(),
                configToSave
            );

            toast.success('Demo configuration saved successfully!');
            setDemoConfig(initialDemoConfig);
            setShowForm(false);
            fetchDemos();
            navigate(`/demo/${response.$id}`);
        } catch (error) {
            toast.error(`Error: ${error.message}`);
            console.error('Save error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderInputField = (label, name, type = 'text', rows = null) => (
        <div className="relative mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label} {name !== 'description' && '*'}
            </label>
            {type === 'color' ? (
                <div className="flex items-center">
                    <input
                        type={type}
                        name={name}
                        value={demoConfig[name]}
                        onChange={handleInputChange}
                        className="w-16 h-10 p-0 rounded-lg border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-2 focus:ring-emerald-500 focus:ring-opacity-50"
                        required={name !== 'description'}
                    />
                    <div
                        className="w-10 h-10 ml-4 rounded-lg border border-gray-300"
                        style={{ backgroundColor: demoConfig[name] }}
                    />
                </div>
            ) : rows ? (
                <textarea
                    name={name}
                    value={demoConfig[name]}
                    onChange={handleInputChange}
                    rows={rows}
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-2 focus:ring-emerald-500 focus:ring-opacity-50"
                    required={name !== 'description'}
                />
            ) : (
                <input
                    type={type}
                    name={name}
                    value={demoConfig[name]}
                    onChange={handleInputChange}
                    className="w-full p-2 rounded-lg border border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-2 focus:ring-emerald-500 focus:ring-opacity-50 hover:border-emerald-600"
                    required={name !== 'description'}
                />
            )}
        </div>
    );



    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 via-gray-100 to-gray-50 py-12 px-4">
            <div className="max-w-7xl mx-auto">
                {/* Dashboard Header */}
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900">Demo Dashboard</h1>
                    <button
                        onClick={() => setShowForm(!showForm)}
                        className="flex items-center gap-2 px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors shadow-lg"
                    >
                        {showForm ? <X className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
                        {showForm ? 'Cancel' : 'Create New Demo'}
                    </button>
                </div>

                {/* Demo Creation Form */}
                {showForm && (
                    <div className="bg-white rounded-2xl shadow-xl p-8 mb-8 border border-gray-100">
                        <form onSubmit={saveDemoConfig} className="space-y-8">
                            <section className="space-y-6">
                                <h2 className="text-2xl font-bold text-gray-900">Basic Information</h2>
                                <div className="grid gap-6 md:grid-cols-2">
                                    {renderInputField('Demo Name', 'name')}
                                    {renderInputField('Description', 'description', 'text', 3)}
                                </div>
                            </section>

                            <section className="space-y-6">
                                <h2 className="text-2xl font-bold text-gray-900">Appearance</h2>
                                <div className="grid gap-6 md:grid-cols-2">
                                    {renderInputField('Primary Color', 'primaryColor', 'color')}
                                    {renderInputField('Secondary Color', 'secondaryColor', 'color')}
                                </div>
                            </section>

                            <section className="space-y-6">
                                <h2 className="text-2xl font-bold text-gray-900">Chat Settings</h2>
                                <div className="grid gap-6 md:grid-cols-2">
                                    {renderInputField('Header Title', 'headerTitle')}
                                    {renderInputField('Welcome Message', 'welcomeMessage')}
                                    {renderInputField('Agent Name', 'agentName')}
                                    {renderInputField('Context Prompt', 'contextPrompt', 'text', 4)}
                                </div>
                            </section>

                            <section className="space-y-6">
                                <div className="flex justify-between items-center">
                                    <h2 className="text-2xl font-bold text-gray-900">Conversation Starters</h2>
                                    <button
                                        type="button"
                                        onClick={addConversationStarter}
                                        className="flex items-center gap-2 px-4 py-2 text-white bg-emerald-600 rounded-lg hover:bg-emerald-700 transition-colors shadow"
                                    >
                                        <Plus className="w-4 h-4"/>
                                        Add Starter
                                    </button>
                                </div>
                                <div className="space-y-4">
                                    {demoConfig.conversationStarters.map((starter, index) => (
                                        <div key={index}
                                             className="flex gap-4 items-center bg-gray-50 p-4 rounded-lg shadow-sm">
                                            <input
                                                type="text"
                                                value={starter.text}
                                                onChange={(e) => updateConversationStarter(index, 'text', e.target.value)}
                                                className="w-100 h-10 p-0 rounded-lg border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-2 focus:ring-emerald-500 focus:ring-opacity-50"
                                                placeholder="Message text"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => removeConversationStarter(index)}
                                                className="p-2 text-red-600 hover:text-red-800 rounded-full hover:bg-red-50"
                                            >
                                                <X className="w-5 h-5"/>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </section>

                            <button
                                type="submit"
                                disabled={isLoading}
                                className="w-full py-4 px-6 text-white text-lg font-medium bg-gradient-to-r from-emerald-600 to-emerald-500 hover:from-emerald-700 hover:to-emerald-600 rounded-xl shadow-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isLoading ? 'Saving...' : 'Save Configuration'}
                            </button>
                        </form>
                    </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {demos.map((demo) => (
                        <div
                            key={demo.$id}
                            className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden hover:shadow-xl transition-shadow cursor-pointer"
                            onClick={() => navigate(`/chat/${demo.$id}`)}
                        >
                            <div className="p-6">
                                <h3 className="text-xl font-semibold text-gray-900 mb-2">{demo.name}</h3>
                                <p className="text-gray-600 mb-4 line-clamp-2">{demo.description || 'No description provided'}</p>
                                <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                        <div
                                            className="w-4 h-4 rounded-full"
                                            style={{ backgroundColor: demo.primaryColor }}
                                        />
                                        <span className="text-sm text-gray-500">
                            {new Date(demo.$createdAt).toLocaleDateString()}
                        </span>
                                    </div>
                                    <div className="flex items-center gap-2" onClick={(e) => e.stopPropagation()}>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate(`/demo/${demo.$id}/edit`);
                                            }}
                                            className="p-2 text-emerald-600 hover:bg-emerald-50 rounded-lg transition-colors"
                                        >
                                            <Edit className="w-5 h-5" />
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                deleteDemo(demo.$id);
                                            }}
                                            className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                                        >
                                            <Trash2 className="w-5 h-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default DemoCreation;