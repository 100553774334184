import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaRobot, FaArrowRight, FaTimes, FaComments } from 'react-icons/fa';

function ChatbotPrompt({ onPromptClick }) {
    const [isVisible, setIsVisible] = useState(false);
    const [hasBeenDismissed, setHasBeenDismissed] = useState(false);

    useEffect(() => {
        // Show prompt after 5 seconds if it hasn't been dismissed
        const timer = setTimeout(() => {
            if (!hasBeenDismissed) {
                setIsVisible(true);
            }
        }, 5000);

        return () => clearTimeout(timer);
    }, [hasBeenDismissed]);

    const handleDismiss = (e) => {
        e.stopPropagation();
        setIsVisible(false);
        setHasBeenDismissed(true);
    };

    const handleClick = () => {
        onPromptClick();
        setIsVisible(false);
        setHasBeenDismissed(true);
    };

    // Animation variants
    const containerVariants = {
        hidden: {
            opacity: 0,
            y: 20,
            scale: 0.95
        },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 20,
                duration: 0.4
            }
        },
        exit: {
            opacity: 0,
            y: 20,
            scale: 0.95,
            transition: {
                duration: 0.3
            }
        }
    };

    const iconAnimation = {
        initial: { rotate: 0 },
        hover: { rotate: 15, scale: 1.1 }
    };

    const pulseAnimation = {
        initial: { scale: 1 },
        pulse: {
            scale: [1, 1.05, 1],
            transition: {
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
            }
        }
    };

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    className="fixed bottom-24 right-5 z-40 sm:bottom-28 sm:right-8"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <div
                        className="relative bg-white rounded-2xl shadow-xl overflow-hidden cursor-pointer group"
                        onClick={handleClick}
                    >
                        {/* Decorative gradient background */}
                        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-white opacity-50" />

                        {/* Main content */}
                        <div className="relative p-6 flex items-start gap-4 max-w-sm">
                            <motion.div
                                className="flex-shrink-0 bg-blue-100 rounded-full p-3"
                                variants={iconAnimation}
                                initial="initial"
                                whileHover="hover"
                            >
                                <FaRobot className="text-blue-600 text-xl" />
                            </motion.div>

                            <div className="flex-grow">
                                <h3 className="font-bold text-gray-900 text-lg mb-1">
                                    Need assistance?
                                </h3>
                                <p className="text-gray-600 mb-4">
                                    Our AI assistant is here to help! Chat with us now.
                                </p>

                                <motion.div
                                    className="flex items-center text-blue-600 font-semibold group-hover:text-blue-700"
                                    variants={pulseAnimation}
                                    initial="initial"
                                    animate="pulse"
                                >
                                    <FaComments className="mr-2" />
                                    <span>Start Chat</span>
                                    <FaArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                                </motion.div>
                            </div>

                            {/* Close button */}
                            <button
                                onClick={handleDismiss}
                                className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors duration-300"
                                aria-label="Close prompt"
                            >
                                <FaTimes className="w-4 h-4" />
                            </button>
                        </div>

                        {/* Bottom accent border */}
                        <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 to-blue-600" />
                    </div>

                    {/* Decorative dots */}
                    <div className="absolute -bottom-3 right-8 flex gap-1">
                        <div className="w-1 h-1 rounded-full bg-blue-500 opacity-75" />
                        <div className="w-1 h-1 rounded-full bg-blue-500 opacity-50" />
                        <div className="w-1 h-1 rounded-full bg-blue-500 opacity-25" />
                    </div>
                </motion.div>
            )}

            {/* Mobile floating button */}
            <AnimatePresence>
                {!isVisible && !hasBeenDismissed && (
                    <motion.button
                        className="fixed bottom-24 right-5 bg-blue-600 text-white p-3 rounded-full shadow-lg z-40 md:hidden flex items-center gap-2"
                        onClick={handleClick}
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <FaComments className="text-xl" />
                    </motion.button>
                )}
            </AnimatePresence>
        </AnimatePresence>
    );
}

export default ChatbotPrompt;