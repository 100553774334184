import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';

const OPENAI_API_KEY = 'sk-grUFafEOO1GG99wuY2_g6ED_Mta_ms4r44Cx6DaLKlT3BlbkFJoZW_Am0ahBfZh6sFBel5E9yFPgl4zcu3at4_xJEmcA';
const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const MAKE_WEBHOOK_URL_GET_BOOKED_SLOTS = 'https://hook.us2.make.com/w9bcdifmbjyjxuty3dg4g2i8r2p0j37j';
const MAKE_WEBHOOK_URL_SCHEDULE = 'https://hook.us2.make.com/dbpaf6h4u3bqxkl41b8t7o6y3q8ni1yb';
const GEMINI_API_KEY = 'AIzaSyCn_znKDE8OF2LHS1jpB1xWm6Mtn_GlQds';
const GEMINI_API_URL = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent';

const openaiApi = axios.create({
    baseURL: OPENAI_API_URL,
    headers: {
        'Authorization': `Bearer ${OPENAI_API_KEY}`,
        'Content-Type': 'application/json'
    }
});

emailjs.init("Gh379KNUpH6MT6AX2");

const thetaAIContext = `
Your role is to act as a highly professional, knowledgeable, and empathetic customer service representative for Theta AI, which specializes in automating business tasks through AI solutions. You should:

Understand the customer's needs: Ask clarifying questions to fully grasp their issue or request.
Provide accurate, concise answers: Ensure your responses are precise and focused on the specific AI solutions that Theta AI offers.
Offer solutions proactively: Suggest relevant services such as AI chatbots, voice assistants, or appointment schedulers based on the customer's inquiry.
Maintain a friendly and professional tone: Be approachable, courteous, and patient in all interactions.
Inform about follow-up: Let the customer know that a team member may reach out if needed.
Suggest scheduling a call: If the conversation progresses and the user seems interested, suggest scheduling a call with a team member.

Theta AI is a leading provider of AI solutions for businesses across various industries. Our key offerings include:

1. Project Management Tools
2. Predictive Analytics
3. Safety Monitoring
4. Quality Control
5. Cost Estimation
6. Supply Chain Optimization
7. Intelligent Automation
8. Data Analytics
9. AI-Powered Customer Service
10. Machine Learning Integration
11. Software Development
12. Website Development

When responding to queries, always structure your answers clearly. Use numbered lists for main points and dashes (-) for sub-points. Each point should start on a new line. Do not use asterisks or any other special characters for emphasis. Provide concise yet informative explanations. If asked about applications in specific industries or about our software and website development capabilities, relate our solutions to the specific needs and opportunities in that area.

If a user asks to schedule a call or book an appointment, respond with: "Certainly! I'd be happy to help you schedule a call with one of our team members. Let me fetch the available time slots for you." Do not ask for their email address or any other information.
`;

function Chatbot({ isOpen, setIsOpen }) {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isWaiting, setIsWaiting] = useState(false);
    const [suggestedQuestions, setSuggestedQuestions] = useState([]);
    const [allPrompts, setAllPrompts] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [availableSlots, setAvailableSlots] = useState({});
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [isCollectingInfo, setIsCollectingInfo] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        fetchPrompts();
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
            sendConversationToEmail();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [messages]);

    useEffect(() => {
        if (isOpen && suggestedQuestions.length === 0 && allPrompts.length > 0) {
            generateSuggestedQuestions();
        }
    }, [isOpen, allPrompts]);

    const fetchPrompts = async () => {
        try {
            const response = await fetch('/prompts.txt');
            const text = await response.text();
            const prompts = text.split('\n').filter(prompt => prompt.trim() !== '');
            setAllPrompts(prompts);
        } catch (error) {
            console.error('Error fetching prompts:', error);
            setAllPrompts([
                "Schedule a call",
                "AI solutions?",
                "Business operations",
                "Custom software",
                "Industries served"
            ]);
        }
    };

    const formatMessage = (content) => {
        // Remove any asterisks
        content = content.replace(/\*/g, '');

        // Ensure proper spacing for numbered lists
        content = content.replace(/(\d+\.)(?=\w)/g, '$1 ');

        // Ensure proper spacing for bullet points
        content = content.replace(/(-|•)(?=\w)/g, '$1 ');

        // Add proper line breaks between sections
        content = content.replace(/\n(?=\d+\.)/g, '\n\n');

        return content;
    };


    const generateSuggestedQuestions = () => {
        const scheduledCallPrompt = "Schedule a call";
        const otherPrompts = allPrompts.filter(prompt => prompt !== scheduledCallPrompt);

        const selectedPrompts = [];
        for (let i = 0; i < 3 && i < otherPrompts.length; i++) {
            const randomIndex = Math.floor(Math.random() * otherPrompts.length);
            selectedPrompts.push(otherPrompts.splice(randomIndex, 1)[0]);
        }

        if (!selectedPrompts.includes(scheduledCallPrompt)) {
            selectedPrompts.push(scheduledCallPrompt);
        }

        setSuggestedQuestions(selectedPrompts);
    };

    const sendMessageToGemini = async (message) => {
        try {
            // Format the conversation history into Gemini's expected structure
            const formattedMessages = messages.map(msg => ({
                role: msg.sender === 'user' ? 'user' : 'model',
                parts: [{ text: msg.text }]
            }));

            // Add the system context as the first message if needed
            if (thetaAIContext) {
                formattedMessages.unshift({
                    role: 'user',
                    parts: [{ text: thetaAIContext }]
                });
            }

            // Add the new message
            formattedMessages.push({
                role: 'user',
                parts: [{ text: message }]
            });

            // Prepare the request body
            const requestBody = {
                contents: formattedMessages,
                generationConfig: {
                    maxOutputTokens: 500,
                    temperature: 0.7
                }
            };

            // Make the API call
            const response = await fetch(`${GEMINI_API_URL}?key=${GEMINI_API_KEY}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`Gemini API error: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();

            // Extract the response text from Gemini's structure
            const responseText = data.candidates?.[0]?.content?.parts?.[0]?.text;

            if (!responseText) {
                throw new Error('No valid response from Gemini API');
            }

            return responseText.trim();
        } catch (error) {
            console.error('Error in sendMessageToGemini:', error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (input.trim()) {
            await sendMessage(input);
        }
    };

    const sendMessage = async (messageText) => {
        if (!messageText.trim() || isWaiting) return;

        const userMessage = { text: messageText, sender: 'user' };
        setMessages(prev => [...prev, userMessage]);
        setInput('');
        setIsWaiting(true);
        setSuggestedQuestions([]);

        try {
            const reply = await sendMessageToGemini(messageText);
            const formattedReply = formatMessage(reply); // Add this line
            const botMessage = { text: formattedReply, sender: 'bot' }; // Use formattedReply instead of reply
            setMessages(prev => [...prev, botMessage]);

            if (messageText.toLowerCase().includes('schedule') ||
                messageText.toLowerCase().includes('appointment') ||
                messageText.toLowerCase().includes('book') ||
                messageText.toLowerCase().includes('call')) {
                await fetchBookedSlots();
                setShowCalendar(true);
            }
        } catch (error) {
            console.error('Error in chatbot response:', error);
            const errorMessage = {
                text: "I apologize, but I'm experiencing technical difficulties at the moment. Please try again later or contact our support team for assistance.",
                sender: 'bot'
            };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setIsWaiting(false);
        }
    };

    const fetchBookedSlots = async () => {
        try {
            const response = await axios.get(MAKE_WEBHOOK_URL_GET_BOOKED_SLOTS);
            const bookedSlotsData = response.data;
            const { currentTime, bookedSlots } = parseBookedSlots(bookedSlotsData);
            const availableSlots = generateAvailableSlots(bookedSlots, currentTime);
            setAvailableSlots(availableSlots);
        } catch (error) {
            console.error('Error fetching booked slots:', error);
            setAvailableSlots({});
        }
    };

    const parseBookedSlots = (data) => {
        const bookedSlots = [];
        let currentTime;

        if (typeof data !== 'string') {
            console.error('Expected string data for parsing booked slots, received:', typeof data);
            return { currentTime: new Date(), bookedSlots: [] };
        }

        const lines = data.split('start:');
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i].trim();
            if (line.startsWith('current time:')) {
                currentTime = new Date(line.split('current time:')[1].trim());
            } else if (line) {
                const [startStr, endStr] = line.split('end:');
                if (startStr && endStr) {
                    const startTime = new Date(startStr.trim());
                    const endTime = new Date(endStr.trim());
                    if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
                        bookedSlots.push({ startTime, endTime });
                    }
                }
            }
        }

        if (!currentTime) {
            currentTime = new Date();
        }

        return { currentTime, bookedSlots };
    };

    const generateAvailableSlots = (bookedSlots, currentTime) => {
        const availableSlots = {};
        const startDate = new Date(currentTime);
        startDate.setHours(0, 0, 0, 0);

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const morningHours = [9, 10];
        const afternoonHours = [14, 15];

        let daysAdded = 0;
        while (daysAdded < 5) {
            if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
                const dayKey = startDate.toISOString().split('T')[0];
                const dayName = daysOfWeek[startDate.getDay()];
                availableSlots[dayKey] = { dayName, slots: [] };

                const dayBookedSlots = bookedSlots.filter(slot =>
                    slot.startTime.toDateString() === startDate.toDateString()
                );

                for (let hour of morningHours) {
                    const slotTime = new Date(startDate);
                    slotTime.setHours(hour, 0, 0, 0);

                    if (slotTime > currentTime &&
                        !isSlotBooked(slotTime, dayBookedSlots) &&
                        !isAdjacentToBookedSlot(slotTime, dayBookedSlots)) {
                        availableSlots[dayKey].slots.push({
                            time: slotTime,
                            type: 'morning'
                        });
                    }
                }

                for (let hour of afternoonHours) {
                    const slotTime = new Date(startDate);
                    slotTime.setHours(hour, 0, 0, 0);

                    if (slotTime > currentTime &&
                        !isSlotBooked(slotTime, dayBookedSlots) &&
                        !isAdjacentToBookedSlot(slotTime, dayBookedSlots)) {
                        availableSlots[dayKey].slots.push({
                            time: slotTime,
                            type: 'afternoon'
                        });
                    }
                }

                if (availableSlots[dayKey].slots.length > 0) {
                    daysAdded++;
                }
            }
            startDate.setDate(startDate.getDate() + 1);
        }

        return availableSlots;
    };

    const isSlotBooked = (slotTime, bookedSlots) => {
        const slotEnd = new Date(slotTime.getTime() + 60 * 60 * 1000);
        return bookedSlots.some(bookedSlot =>
            (slotTime >= bookedSlot.startTime && slotTime < bookedSlot.endTime) ||
            (slotEnd > bookedSlot.startTime && slotEnd <= bookedSlot.endTime) ||
            (slotTime <= bookedSlot.startTime && slotEnd >= bookedSlot.endTime)
        );
    };

    const isAdjacentToBookedSlot = (slotTime, bookedSlots) => {
        const oneHourBefore = new Date(slotTime.getTime() - 60 * 60 * 1000);
        const twoHoursAfter = new Date(slotTime.getTime() + 2 * 60 * 60 * 1000);

        return bookedSlots.some(bookedSlot =>
            (oneHourBefore >= bookedSlot.startTime && oneHourBefore < bookedSlot.endTime) ||
            (twoHoursAfter > bookedSlot.startTime && twoHoursAfter <= bookedSlot.endTime)
        );
    };

    const formatTimeSlot = (date) => {
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };

    const formatDateForWebhook = (date) => {
        return date.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }).replace(',', '');
    };

    const handleSlotSelection = (date, slot) => {
        setSelectedDate(date);
        setSelectedSlot(slot.time);
        if (!isCollectingInfo) {
            setIsCollectingInfo(true);
            setMessages(prev => [...prev, {
                text: `You've selected ${formatTimeSlot(slot.time)} on ${new Date(date).toLocaleDateString()}. Please provide your contact information to confirm the appointment.`,
                sender: 'bot'
            }]);
        }
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const scheduleAppointment = async () => {
        if (!selectedSlot || !userEmail || !userName) {
            return;
        }

        if (!validateEmail(userEmail)) {
            setMessages(prev => [...prev, {
                text: "Please enter a valid email address.",
                sender: 'bot'
            }]);
            return;
        }

        try {
            const startTime = new Date(selectedSlot);
            const endTime = new Date(selectedSlot);
            endTime.setHours(endTime.getHours() + 1);

            const formattedStartDate = formatDateForWebhook(startTime);
            const formattedEndDate = formatDateForWebhook(endTime);

            const appointmentData = {
                email: userEmail,
                name: userName,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                conversation: messages.map(msg => `${msg.sender}: ${msg.text}`).join('\n\n')
            };

            const response = await axios.post(MAKE_WEBHOOK_URL_SCHEDULE, appointmentData);

            if (response.data && response.data.error) {
                throw new Error(response.data.error);
            }

            const confirmationMessage = {
                text: `Perfect! Your appointment is confirmed for ${formattedStartDate} EST. You'll receive a confirmation email shortly with all the details. Is there anything else I can help you with?`,
                sender: 'bot',
                isConfirmation: true
            };
            setMessages(prev => [...prev, confirmationMessage]);

            setShowCalendar(false);
            setIsCollectingInfo(false);
            setSelectedSlot(null);
            setSelectedDate(null);
            setUserEmail('');
            setUserName('');

        } catch (error) {
            console.error('Error scheduling appointment:', error);
            setMessages(prev => [...prev, {
                text: `I apologize, but we're experiencing technical difficulties. Please try again or contact our support team at support@theta-ai.org.`,
                sender: 'bot'
            }]);
        }
    };

    const sendConversationToEmail = async () => {
        if (messages.length === 0) return;

        const conversationText = messages.map(msg => `${msg.sender}: ${msg.text}`).join('\n\n');

        const templateParams = {
            to_email: 'info@theta-ai.org',
            conversation: conversationText
        };

        try {
            await emailjs.send('service_kn47xhr', 'template_4ogxifc', templateParams);
        } catch (error) {
            console.error('Error sending conversation to email:', error);
        }
    };

    const handleClose = () => {
        sendConversationToEmail();
        setIsOpen(false);
        setSuggestedQuestions([]);
        setShowCalendar(false);
        setSelectedSlot(null);
        setSelectedDate(null);
        setIsCollectingInfo(false);
        setUserEmail('');
        setUserName('');
    };

    return (
        <div className="fixed bottom-3 right-3 z-50">
            {isOpen ? (
                <div className="bg-white rounded-2xl shadow-2xl w-[350px] h-[500px] flex flex-col overflow-hidden border border-gray-100">
                    {/* Header */}
                    <div className="bg-gradient-to-r from-blue-500 to-blue-600 p-4 flex justify-between items-center relative overflow-hidden">
                        <div className="absolute inset-0 opacity-20">
                            <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(255,255,255,0.3),rgba(255,255,255,0))]"></div>
                        </div>
                        <div className="flex items-center space-x-3 relative z-10">
                            <div className="w-8 h-8 bg-white/20 backdrop-blur-sm rounded-lg flex items-center justify-center">
                                <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </div>
                            <div>
                                <h3 className="font-bold text-white tracking-wide text-sm">Theta AI Assistant</h3>
                                <div className="flex items-center space-x-1.5">
                                    <span className="w-1.5 h-1.5 bg-green-400 rounded-full animate-pulse"></span>
                                    <p className="text-[11px] text-white/90">AI Powered Support</p>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={handleClose}
                            className="relative z-10 p-1.5 hover:bg-white/10 rounded-lg transition-colors duration-200"
                        >
                            <svg className="w-5 h-5 text-white/80" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    {/* Messages Area */}
                    <div className="flex-grow overflow-y-auto p-4 space-y-4 bg-gray-50">
                        {messages.length === 0 && (
                            <div className="text-center space-y-4 py-8">
                                <div className="relative">
                                    <div className="w-16 h-16 mx-auto bg-gradient-to-r from-blue-500 to-blue-600 rounded-2xl flex items-center justify-center transform -rotate-12">
                                        <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                        </svg>
                                    </div>
                                    <div className="absolute -bottom-2 -right-2 w-8 h-8 bg-blue-600 rounded-xl flex items-center justify-center transform rotate-12">
                                        <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M13 10V3L4 14h7v7l9-11h-7z" />
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <h4 className="text-base font-bold text-gray-900 mb-1">Welcome to Theta AI</h4>
                                    <p className="text-sm text-gray-600 mb-4">How can I assist you today?</p>
                                </div>
                                <div className="grid grid-cols-2 gap-2 px-2">
                                    {suggestedQuestions.map((question, index) => (
                                        <button
                                            key={index}
                                            onClick={() => sendMessage(question)}
                                            className={`px-3 py-2 rounded-xl text-xs font-medium transition-all duration-200 ${
                                                question.toLowerCase().includes('schedule')
                                                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:shadow-lg hover:shadow-blue-500/20'
                                                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-blue-600 border border-gray-200'
                                            }`}
                                        >
                                            {question}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}

                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                            >
                                <div className={`max-w-[85%] p-3 rounded-2xl shadow-sm ${
                                    message.sender === 'user'
                                        ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white'
                                        : message.isConfirmation
                                            ? 'bg-green-500 text-white'
                                            : 'bg-white text-gray-700 border border-gray-100'
                                }`}>
                                <pre className="whitespace-pre-wrap font-sans text-sm leading-relaxed">
                                    {message.text}
                                </pre>
                                </div>
                            </div>
                        ))}

                        {isWaiting && (
                            <div className="flex items-center space-x-2 p-3">
                                <div className="flex space-x-1">
                                    <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce"></div>
                                    <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{animationDelay: '0.1s'}}></div>
                                    <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce" style={{animationDelay: '0.2s'}}></div>
                                </div>
                            </div>
                        )}

                        {showCalendar && (
                            <div className="mt-3 bg-white rounded-xl shadow-md p-4 space-y-3 border border-gray-100">
                                <h4 className="text-sm font-semibold text-gray-900 mb-2">Available Times (EST)</h4>
                                {Object.entries(availableSlots).map(([date, { dayName, slots }]) => (
                                    <div key={date} className="bg-gray-50 p-3 rounded-xl border border-gray-100">
                                        <h5 className="text-xs font-medium text-gray-700 mb-2">
                                            {dayName}, {new Date(date).toLocaleDateString()}
                                        </h5>
                                        <div className="grid grid-cols-2 gap-2">
                                            {slots.map((slot, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => handleSlotSelection(date, slot)}
                                                    className={`p-2 rounded-lg text-xs font-medium transition-all duration-200 ${
                                                        selectedSlot && selectedSlot.getTime() === slot.time.getTime()
                                                            ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg shadow-blue-500/20'
                                                            : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-blue-600 border border-gray-200'
                                                    }`}
                                                >
                                                    {formatTimeSlot(slot.time)}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {isCollectingInfo && (
                            <div className="mt-3 bg-white rounded-xl shadow-md p-4 space-y-3 border border-gray-100">
                                <h4 className="text-sm font-semibold text-gray-900 mb-2">Contact Information</h4>
                                <div className="space-y-3">
                                    <input
                                        type="text"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        placeholder="Full Name"
                                        className="w-full px-3 py-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900 placeholder-gray-500"
                                    />
                                    <input
                                        type="email"
                                        value={userEmail}
                                        onChange={(e) => setUserEmail(e.target.value)}
                                        placeholder="Email Address"
                                        className="w-full px-3 py-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900 placeholder-gray-500"
                                    />
                                    <button
                                        onClick={scheduleAppointment}
                                        disabled={!selectedSlot || !userEmail || !userName}
                                        className={`w-full py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                                            !selectedSlot || !userEmail || !userName
                                                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                                : 'bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:shadow-lg hover:shadow-blue-500/20'
                                        }`}
                                    >
                                        Confirm Appointment
                                    </button>
                                </div>
                            </div>
                        )}

                        <div ref={messagesEndRef} />
                    </div>

                    {/* Input Area */}
                    <form onSubmit={handleSubmit} className="p-4 bg-white border-t border-gray-100">
                        <div className="flex items-center space-x-2">
                            <input
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Type your message..."
                                className="flex-grow px-4 py-2 bg-gray-50 border border-gray-200 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900 placeholder-gray-500"
                                disabled={isWaiting}
                            />
                            <button
                                type="submit"
                                disabled={isWaiting || !input.trim()}
                                className={`p-2 rounded-xl transition-all duration-200 ${
                                    isWaiting || !input.trim()
                                        ? 'bg-gray-100 cursor-not-allowed'
                                        : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:shadow-lg hover:shadow-blue-500/20'
                                }`}
                            >
                                <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M13 5l7 7m0 0l-7 7m7-7H4" />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <button
                    onClick={() => setIsOpen(true)}
                    className="group relative bg-gradient-to-r from-blue-500 to-blue-600 p-3.5 rounded-2xl hover:shadow-2xl hover:shadow-blue-500/20 transition-all duration-200"
                >
                    <div className="absolute inset-0 rounded-2xl bg-blue-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></div>
                    <svg className="relative z-10 w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                    </svg>
                </button>
            )}
        </div>
    );
}

export default Chatbot;