// components/DemoDashboard.jsx
import React, { useState, useEffect } from 'react';
import { databases, DEMO_DATABASE_ID, DEMO_COLLECTION_ID } from '../config/appwrite';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

function DemoDashboard() {
    const [demos, setDemos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        loadDemos();
    }, []);

    const loadDemos = async () => {
        try {
            const response = await databases.listDocuments(
                DEMO_DATABASE_ID,
                DEMO_COLLECTION_ID
            );
            setDemos(response.documents);
        } catch (error) {
            toast.error('Failed to load demos');
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (demoId) => {
        if (window.confirm('Are you sure you want to delete this demo?')) {
            try {
                await databases.deleteDocument(
                    DEMO_DATABASE_ID,
                    DEMO_COLLECTION_ID,
                    demoId
                );
                toast.success('Demo deleted successfully');
                loadDemos();
            } catch (error) {
                toast.error('Failed to delete demo');
                console.error(error);
            }
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
            </div>
        );
    }

    return (
        <div className="bg-gray-50 min-h-screen">
            <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-900">Demo Dashboard</h1>
                    <button
                        onClick={() => navigate('/demo/create')}
                        className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
                    >
                        Create New Demo
                    </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {demos.map((demo) => (
                        <div
                            key={demo.$id}
                            className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden hover:shadow-lg transition-shadow"
                        >
                            <div className="p-6">
                                <div className="flex justify-between items-start">
                                    <h2 className="text-xl font-semibold text-gray-900 mb-2">
                                        {demo.name}
                                    </h2>
                                    <button
                                        onClick={() => handleDelete(demo.$id)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                    </button>
                                </div>
                                <p className="text-gray-600 mb-4 line-clamp-2">{demo.description}</p>
                                <div className="flex justify-between items-center">
                                    <span className="text-sm text-gray-500">
                                        Agent: {demo.agentName}
                                    </span>
                                    <button
                                        onClick={() => navigate(`/demo/${demo.$id}`)}
                                        className="text-emerald-600 hover:text-emerald-700 font-medium"
                                    >
                                        View Demo →
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {demos.length === 0 && (
                    <div className="text-center py-12">
                        <p className="text-gray-500 text-lg">No demos created yet</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DemoDashboard;