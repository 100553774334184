import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Chatbot from './components/Chatbot';
import ChatbotPrompt from './components/ChatbotPrompt';
import Demo1 from "./components/Demo1";
import DemoCreation from "./pages/DemoCreation";
import DemoTemplate from "./components/DemoTemplate";
import { Toaster } from 'react-hot-toast';
import DemoDashboard from "./components/DemoDashboard";

function App() {
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);
    const [initialMessage, setInitialMessage] = useState('');

    const handleChatbotTrigger = (message = '') => {
        setIsChatbotOpen(true);
        if (message) {
            setInitialMessage(message);
        }
    };

    const handlePromptClick = () => {
        handleChatbotTrigger("Hi! I'd like to learn more about your AI solutions");
    };

    return (
        <Router>
            <Toaster 
                position="top-right"
                toastOptions={{
                    duration: 4000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: '#059669',
                        }
                    },
                    error: {
                        duration: 5000,
                        theme: {
                            primary: '#DC2626',
                        }
                    },
                }}
            />
            <Routes>
                {/* Demo Routes */}
                <Route path="/demo1" element={<Demo1 />} />
                <Route path="/demo/create" element={<DemoCreation />} />
                <Route path="/demo/:id/edit" element={<DemoTemplate isEditing={true} />} />
                <Route path="/chat/:id" element={<DemoTemplate />} />
                <Route path="/dashboard" element={<DemoDashboard />} />

                {/* Main App Routes */}
                <Route path="*" element={
                    <div className="flex flex-col min-h-screen bg-gray-50">
                        <Header />
                        <main className="flex-grow">
                            <Routes>
                                <Route path="/" element={<Home onChatbotTrigger={handleChatbotTrigger} />} />
                                <Route path="/services" element={<Services onChatbotTrigger={handleChatbotTrigger} />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/contact" element={<Contact />} />
                            </Routes>
                        </main>
                        <Footer />
                        <Chatbot
                            isOpen={isChatbotOpen}
                            setIsOpen={setIsChatbotOpen}
                            initialPrompt={initialMessage}
                            setInitialPrompt={setInitialMessage}
                        />
                        <ChatbotPrompt onPromptClick={handlePromptClick} />
                    </div>
                } />
            </Routes>
        </Router>
    );
}

export default App;