import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png'; // Make sure this path is correct

function Footer() {
    return (
        <footer className="bg-gray-800 text-white">
            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
                    <div>
                        <Link to="/" className="flex items-center mb-4">
                            <img src={logo} alt="Theta AI Logo" className="h-8 w-auto mr-2" />
                            <span className="text-xl font-bold">Theta AI</span>
                        </Link>
                        <p className="text-gray-400">Empowering businesses with intelligent AI solutions.</p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold mb-4 text-yellow-400">Quick Links</h4>
                        <ul className="space-y-2">
                            <li><Link to="/" className="text-gray-400 hover:text-yellow-400 transition duration-300">Home</Link></li>
                            <li><Link to="/services" className="text-gray-400 hover:text-yellow-400 transition duration-300">Services</Link></li>
                            <li><Link to="/about" className="text-gray-400 hover:text-yellow-400 transition duration-300">About Us</Link></li>
                            <li><Link to="/contact" className="text-gray-400 hover:text-yellow-400 transition duration-300">Contact</Link></li>


                        </ul>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold mb-4 text-yellow-400">Contact Us</h4>
                        <p className="text-gray-400">Email: info@theta-ai.org</p>
                    </div>
                </div>
                <div className="border-t border-gray-700 pt-8 text-center text-gray-400">
                    <p>&copy; 2024 Theta AI. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;