import React from 'react';
import { motion, useScroll } from "framer-motion";
import {
    FaRobot,
    FaCalendarCheck,
    FaBrain,
    FaChartLine,
    FaComments,
    FaArrowRight,
    FaCheck,
    FaClock,
    FaCode,
    FaLightbulb,
    FaRocket,
    FaShieldAlt,
    FaCogs,
    FaChartBar,
    FaUserFriends,
    FaBolt,
    FaMagic,
    FaHeadset,
    FaRegLightbulb,
    FaInbox,
    FaUsers,
    FaGlobe,
    FaStar,
    FaSmile,
    FaSpinner,
    FaExchangeAlt,
    FaPhoneAlt,
    FaUserClock,
    FaCheckCircle,
    FaTimesCircle,
    FaDatabase,
    FaCalendar,
    FaFileAlt,
    FaLink,
    FaCreditCard,
    FaSearch,
    FaTools
} from 'react-icons/fa';

import chatbotImage from '../images/chatbot.jpg';

function Home({ onChatbotTrigger }) {
    const { scrollY } = useScroll();

    // Animation variants
    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, ease: "easeOut" }
        }
    };

    const slideIn = {
        hidden: { x: -50, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: { duration: 0.8, ease: "easeOut" }
        }
    };

    const slideUp = {
        hidden: { y: 50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.8, ease: "easeOut" }
        }
    };

    const staggerContainer = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.3
            }
        }
    };


    // Main features
    const features = [
        {
            icon: <FaBrain className="text-6xl text-blue-600" />,
            title: "24/7 Customer Support",
            description: "Always-on support that handles customer queries instantly",
            prompt: "Tell me more about your 24/7 support capabilities",
            highlight: "Never Miss a Customer",
            gradient: "from-blue-500 to-purple-500"
        },
        {
            icon: <FaBolt className="text-6xl text-purple-600" />,
            title: "Smart Responses",
            description: "AI that understands context and provides accurate answers",
            prompt: "How accurate are your chatbot's responses?",
            highlight: "Context-Aware AI",
            gradient: "from-purple-500 to-pink-500"
        },
        {
            icon: <FaGlobe className="text-6xl text-green-600" />,
            title: "Multilingual Support",
            description: "Communicate with customers in their preferred language",
            prompt: "What languages does your chatbot support?",
            highlight: "Global Reach",
            gradient: "from-green-500 to-teal-500"
        },
        {
            icon: <FaChartLine className="text-6xl text-blue-600" />,
            title: "Analytics & Insights",
            description: "Track performance and improve customer satisfaction",
            prompt: "What kind of analytics do you provide?",
            highlight: "Data-Driven",
            gradient: "from-blue-600 to-indigo-600"
        }
    ];

    // Business benefits
    const benefits = [
        {
            icon: <FaClock className="text-4xl" />,
            value: "70%",
            label: "Time Saved",
            description: "Reduction in response time",
            prompt: "How does your chatbot reduce response times?"
        },
        {
            icon: <FaChartBar className="text-4xl" />,
            value: "50%",
            label: "Cost Reduction",
            description: "Lower support costs",
            prompt: "Can you explain the cost savings with your chatbot?"
        },
        {
            icon: <FaSmile className="text-4xl" />,
            value: "95%",
            label: "Satisfaction",
            description: "Customer happiness score",
            prompt: "How do you maintain such high customer satisfaction?"
        },
        {
            icon: <FaUsers className="text-4xl" />,
            value: "24/7",
            label: "Availability",
            description: "Round-the-clock support",
            prompt: "Tell me about your chatbot's availability"
        }
    ];


    // Old vs New comparison data
    const comparisonData = [
        {
            title: "Response Time",
            old: {
                icon: <FaUserClock className="text-4xl text-red-500" />,
                label: "Traditional Support",
                metrics: ["24-48 hour response time", "Limited availability", "Time zone constraints"],
                color: "border-red-200 bg-red-50"
            },
            new: {
                icon: <FaBolt className="text-4xl text-green-500" />,
                label: "AI-Powered Support",
                metrics: ["Instant responses", "24/7 availability", "No time zone barriers"],
                color: "border-green-200 bg-green-50"
            }
        },
        {
            title: "Scalability",
            old: {
                icon: <FaUsers className="text-4xl text-red-500" />,
                label: "Human Teams",
                metrics: ["Limited by team size", "High training costs", "Inconsistent responses"],
                color: "border-red-200 bg-red-50"
            },
            new: {
                icon: <FaRobot className="text-4xl text-green-500" />,
                label: "AI Solution",
                metrics: ["Unlimited scaling", "One-time training", "Consistent experience"],
                color: "border-green-200 bg-green-50"
            }
        },
        {
            title: "Cost Efficiency",
            old: {
                icon: <FaTimesCircle className="text-4xl text-red-500" />,
                label: "Traditional Costs",
                metrics: ["High operational costs", "Increasing overhead", "Training expenses"],
                color: "border-red-200 bg-red-50"
            },
            new: {
                icon: <FaCheckCircle className="text-4xl text-green-500" />,
                label: "AI Economics",
                metrics: ["Reduced operational costs", "Predictable pricing", "No overhead"],
                color: "border-green-200 bg-green-50"
            }
        }
    ];

    // AI Capabilities data
    const aiCapabilities = [
        {
            icon: <FaCalendar className="text-4xl text-blue-600" />,
            title: "Meeting Scheduling",
            description: "Automatically schedule, reschedule, and manage appointments across time zones",
            features: [
                "Calendar integration",
                "Time zone handling",
                "Automated reminders",
                "Meeting link generation"
            ]
        },
        {
            icon: <FaDatabase className="text-4xl text-purple-600" />,
            title: "Database Access",
            description: "Securely access and query multiple databases for real-time information",
            features: [
                "Real-time data queries",
                "Secure authentication",
                "Multiple database support",
                "Custom API integration"
            ]
        },
        {
            icon: <FaFileAlt className="text-4xl text-green-600" />,
            title: "Document Processing",
            description: "Process and analyze documents for quick information retrieval",
            features: [
                "Document parsing",
                "Information extraction",
                "Format conversion",
                "Content summarization"
            ]
        },
        {
            icon: <FaLink className="text-4xl text-orange-600" />,
            title: "System Integration",
            description: "Seamlessly integrate with your existing business systems",
            features: [
                "CRM integration",
                "ERP connectivity",
                "Workflow automation",
                "Custom API support"
            ]
        }
    ];

    // Action button component
    const ActionButton = ({ onClick, primary, children, className = '' }) => (
        <motion.button
            onClick={onClick}
            className={`px-8 py-4 rounded-full text-lg font-bold transition-all duration-300 flex items-center gap-3 ${
                primary
                    ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white hover:from-blue-700 hover:to-blue-800 shadow-lg hover:shadow-xl'
                    : 'bg-white text-blue-600 hover:bg-blue-50'
            } ${className}`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
        >
            {children}
        </motion.button>
    );

    return (
        <div className="home">
            {/* Hero Section */}
            <section className="relative min-h-screen">
                <motion.div
                    initial={{ scale: 1.1, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 1.2 }}
                    className="absolute inset-0"
                >
                    <img
                        src={chatbotImage}
                        alt="AI Chatbot Interface"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-br from-blue-900/95 via-blue-800/90 to-black/95" />
                </motion.div>
                <div className="absolute inset-0 flex items-center">
                    <div className="container mx-auto px-4">
                        <div className="max-w-4xl">
                            <motion.h1
                                className="text-7xl md:text-8xl font-bold mb-8 text-white"
                                variants={slideIn}
                                initial="hidden"
                                animate="visible"
                            >
                                AI Support
                                <br />
                                <span className="bg-gradient-to-r from-blue-400 to-blue-200 bg-clip-text text-transparent">
                                    Made Simple
                                </span>
                            </motion.h1>
                            <motion.p
                                className="text-2xl md:text-3xl mb-12 text-blue-100"
                                variants={slideUp}
                                initial="hidden"
                                animate="visible"
                            >
                                Transform your customer support with AI that understands, learns, and delights
                            </motion.p>
                            <motion.div
                                className="flex flex-col sm:flex-row gap-6"
                                variants={fadeIn}
                                initial="hidden"
                                animate="visible"
                            >
                                <ActionButton
                                    onClick={() => onChatbotTrigger("I'd like to see how your chatbot works in action")}
                                    primary
                                >
                                    <FaRocket className="text-xl" />
                                    Try It Now
                                </ActionButton>
                                <ActionButton
                                    onClick={() => onChatbotTrigger("I'd like to schedule a demo of your chatbot solution")}
                                >
                                    <FaCalendarCheck className="text-xl" />
                                    Schedule Demo
                                </ActionButton>
                            </motion.div>
                        </div>
                    </div>
                </div>

            </section>

            {/* Features Grid */}
            <section className="py-24 bg-white">
                <div className="container mx-auto px-4">
                    <motion.div
                        className="text-center mb-16"
                        variants={fadeIn}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h2 className="text-5xl font-bold mb-6 text-gray-900">
                            Why Choose Our Chatbot?
                        </h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Experience the next generation of customer support automation
                        </p>
                    </motion.div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
                        {features.map((feature, index) => (
                            <motion.div
                                key={index}
                                className="relative group cursor-pointer"
                                variants={fadeIn}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                whileHover={{ y: -10 }}
                                onClick={() => onChatbotTrigger(feature.prompt)}
                            >
                                <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300">
                                    <motion.div
                                        className="mb-6"
                                        animate={{
                                            y: [-10, 0, -10],
                                            transition: {
                                                duration: 3,
                                                repeat: Infinity,
                                                ease: "easeInOut"
                                            }
                                        }}
                                    >
                                        {feature.icon}
                                    </motion.div>
                                    <h3 className="text-2xl font-bold mb-4 text-gray-900">{feature.title}</h3>
                                    <p className="text-gray-600 mb-6">{feature.description}</p>
                                    <span className={`inline-block bg-gradient-to-r ${feature.gradient} px-4 py-2 rounded-full text-sm font-semibold text-white`}>
                                        {feature.highlight}
                                    </span>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Advanced AI Capabilities Section */}
            <section className="py-24 bg-gradient-to-br from-gray-50 to-white">
                <div className="container mx-auto px-4">
                    <motion.div
                        className="text-center mb-16"
                        variants={fadeIn}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h2 className="text-5xl font-bold mb-6 text-gray-900">
                            Advanced AI Capabilities
                        </h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            Our AI agents can perform complex tasks and integrate with your existing systems
                        </p>
                    </motion.div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
                        {aiCapabilities.map((capability, index) => (
                            <motion.div
                                key={index}
                                className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
                                variants={fadeIn}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                whileHover={{ y: -5 }}
                            >
                                <div className="flex items-center mb-6">
                                    {capability.icon}
                                    <h3 className="text-2xl font-bold ml-4 text-gray-900">{capability.title}</h3>
                                </div>
                                <p className="text-gray-600 mb-6">{capability.description}</p>
                                <ul className="space-y-3">
                                    {capability.features.map((feature, idx) => (
                                        <li key={idx} className="flex items-center text-gray-700">
                                            <FaCheck className="text-green-500 mr-3" />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </motion.div>
                        ))}
                    </div>

                    <motion.div
                        className="text-center mt-12"
                        variants={fadeIn}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <ActionButton
                            onClick={() => onChatbotTrigger("I want to learn more about your AI's advanced capabilities")}
                            primary
                            className="inline-flex"
                        >
                            <FaRobot className="text-xl" />
                            Explore AI Capabilities
                        </ActionButton>
                    </motion.div>
                </div>
            </section>

            {/* ROI Section */}
            <section className="py-24 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 text-white">
                <div className="container mx-auto px-4">
                    <motion.div
                        className="text-center mb-16"
                        variants={fadeIn}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h2 className="text-5xl font-bold mb-6">
                            Real Results, Real Impact
                        </h2>
                        <p className="text-xl text-blue-100 max-w-3xl mx-auto">
                            See the difference our chatbot makes for businesses like yours
                        </p>
                    </motion.div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
                        {benefits.map((benefit, index) => (
                            <motion.div
                                key={index}
                                variants={fadeIn}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                whileHover={{ y: -5 }}
                                onClick={() => onChatbotTrigger(benefit.prompt)}
                                className="bg-white/10 backdrop-blur-sm p-8 rounded-2xl text-center cursor-pointer"
                            >
                                <div className="text-blue-300 mb-4">{benefit.icon}</div>
                                <div className="text-5xl font-bold text-white mb-2">{benefit.value}</div>
                                <div className="text-xl font-semibold text-blue-200 mb-2">{benefit.label}</div>
                                <p className="text-blue-100">{benefit.description}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Old vs New Comparison Section */}
            <section className="py-24 bg-gray-50">
                <div className="container mx-auto px-4">
                    <motion.div
                        className="text-center mb-16"
                        variants={fadeIn}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <h2 className="text-5xl font-bold mb-6 text-gray-900">
                            Traditional vs AI-Powered Support
                        </h2>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                            See how our AI solution transforms traditional customer support
                        </p>
                    </motion.div>

                    <div className="max-w-7xl mx-auto">
                        {comparisonData.map((comparison, index) => (
                            <motion.div
                                key={index}
                                className="mb-12 last:mb-0"
                                variants={fadeIn}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                            >
                                <div className="text-2xl font-bold mb-6 text-center text-gray-800">
                                    {comparison.title}
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                    {/* Old System */}
                                    <motion.div
                                        className={`p-8 rounded-2xl border-2 ${comparison.old.color} relative overflow-hidden`}
                                        whileHover={{ y: -5 }}
                                    >
                                        <div className="flex items-center mb-6">
                                            {comparison.old.icon}
                                            <h3 className="text-xl font-bold ml-4 text-gray-800">
                                                {comparison.old.label}
                                            </h3>
                                        </div>
                                        <ul className="space-y-4">
                                            {comparison.old.metrics.map((metric, idx) => (
                                                <li key={idx} className="flex items-center text-gray-700">
                                                    <FaTimesCircle className="text-red-500 mr-3" />
                                                    {metric}
                                                </li>
                                            ))}
                                        </ul>
                                    </motion.div>

                                    {/* New System */}
                                    <motion.div
                                        className={`p-8 rounded-2xl border-2 ${comparison.new.color} relative overflow-hidden`}
                                        whileHover={{ y: -5 }}
                                    >
                                        <div className="flex items-center mb-6">
                                            {comparison.new.icon}
                                            <h3 className="text-xl font-bold ml-4 text-gray-800">
                                                {comparison.new.label}
                                            </h3>
                                        </div>
                                        <ul className="space-y-4">
                                            {comparison.new.metrics.map((metric, idx) => (
                                                <li key={idx} className="flex items-center text-gray-700">
                                                    <FaCheckCircle className="text-green-500 mr-3" />
                                                    {metric}
                                                </li>
                                            ))}
                                        </ul>
                                    </motion.div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Final CTA */}
            <section className="py-32 bg-gradient-to-br from-blue-600 to-blue-900 relative overflow-hidden">
                <motion.div
                    className="absolute inset-0 opacity-30"
                    animate={{
                        backgroundPosition: ["0% 0%", "100% 100%"],
                        transition: {
                            duration: 20,
                            repeat: Infinity,
                            repeatType: "reverse"
                        }
                    }}
                    style={{
                        backgroundImage: "radial-gradient(circle at center, white 1px, transparent 1px)",
                        backgroundSize: "40px 40px"
                    }}
                />

                <div className="container mx-auto px-4 relative">
                    <motion.div
                        className="text-center max-w-4xl mx-auto text-white"
                        variants={staggerContainer}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                    >
                        <motion.h2
                            className="text-5xl md:text-7xl font-bold mb-8"
                            variants={slideUp}
                        >
                            Ready to Transform Your Support?
                        </motion.h2>
                        <motion.p
                            className="text-2xl mb-12 text-blue-100"
                            variants={slideUp}
                        >
                            Join successful businesses already using our AI chatbot
                        </motion.p>

                        <motion.div
                            className="flex flex-col sm:flex-row gap-6 justify-center items-center"
                            variants={fadeIn}
                        >
                            <ActionButton
                                onClick={() => onChatbotTrigger("I want to implement your chatbot solution")}
                                primary
                                className="bg-white text-blue-600 hover:bg-blue-50"
                            >
                                <FaRocket className="text-xl" />
                                Get Started Now
                            </ActionButton>

                            <ActionButton
                                onClick={() => onChatbotTrigger("I'd like to see a live demo of the chatbot")}
                                className="border-2 border-white bg-blue-800"
                            >
                                <FaRegLightbulb className="text-xl" />
                                Try Our Chatbot
                            </ActionButton>
                        </motion.div>
                    </motion.div>
                </div>
            </section>

            {/* Scroll Progress Indicator */}
            <motion.div
                className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 to-blue-600 origin-left z-50"
                style={{
                    scaleX: scrollY ? scrollY.get() / (document.documentElement.scrollHeight - window.innerHeight) : 0
                }}
            />
        </div>
    );
}

export default Home;