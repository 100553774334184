import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { databases, DEMO_DATABASE_ID, DEMO_COLLECTION_ID } from '../config/appwrite';
import { MessageSquare, Send, Bot, User, Loader2, Sparkles, Info, Circle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const GEMINI_API_KEY = 'AIzaSyCa6u9SZbDHDsZU9XjThzU0obuAaqia-jE';

const DemoTemplate = () => {
    const { id } = useParams();
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [conversationStarters, setConversationStarters] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [showStarters, setShowStarters] = useState(true);
    const messagesEndRef = useRef(null);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        loadDemoConfig();
    }, [id]);

    useEffect(() => {
        if (!isTyping) {
            scrollToBottom();
        }
    }, [messages, isTyping]);

    const loadDemoConfig = async () => {
        try {
            const response = await databases.getDocument(
                DEMO_DATABASE_ID,
                DEMO_COLLECTION_ID,
                id
            );
            setConfig(response);
            setConversationStarters(JSON.parse(response.conversationStarters || '[]'));
            setMessages([{
                role: 'assistant',
                content: response.welcomeMessage
            }]);
        } catch (error) {
            console.error('Error loading demo config:', error);
        } finally {
            setLoading(false);
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const formatMessageText = (text) => {
        if (!text.includes('**')) return text;

        const parts = text.split('**');
        return parts.map((part, index) => {
            // Odd indices (1, 3, 5, etc.) should be bold
            if (index % 2 === 1) {
                return `<strong>${part}</strong>`;
            }
            return part;
        }).join('');
    };

    const handleSendMessage = async (text = inputMessage) => {
        if ((!text.trim() && !inputMessage.trim()) || isTyping) return;

        const messageToSend = text.trim() || inputMessage.trim();
        setShowStarters(false);

        // Format the user's message before adding it to the chat
        const formattedUserMessage = formatMessageText(messageToSend);
        setMessages(prev => [...prev, {
            role: 'user',
            content: formattedUserMessage
        }]);

        setInputMessage('');
        setIsTyping(true);

        try {
            const response = await fetch(
                `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=${GEMINI_API_KEY}`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        contents: [{
                            parts: [{ text: `${config.contextPrompt}\n\nUser: ${messageToSend}` }]
                        }]
                    })
                }
            );

            const data = await response.json();

            if (data.candidates && data.candidates[0].content) {
                // Format the assistant's response before adding it to the chat
                const formattedResponse = formatMessageText(data.candidates[0].content.parts[0].text);
                setMessages(prev => [...prev, {
                    role: 'assistant',
                    content: formattedResponse
                }]);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessages(prev => [...prev, {
                role: 'assistant',
                content: "I apologize, but I encountered an error. Please try again."
            }]);
        } finally {
            setIsTyping(false);
        }
    };

    const MessageBubble = ({ message, isLast }) => {
        const isUser = message.role === 'user';

        return (
            <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-6 relative`}>
                <div
                    className={`flex ${isUser ? 'flex-row-reverse' : 'flex-row'} items-start gap-3 max-w-[85%] relative`}
                    style={{ transform: 'translateZ(0)' }}
                >
                    <div
                        className="w-10 h-10 rounded-full flex items-center justify-center shadow-md transition-all duration-200 hover:shadow-lg"
                        style={{background : config.primaryColor}}
                    >
                        {isUser ? (
                            <User className="w-5 h-5 text-white" />
                        ) : (
                            <Bot className="w-5 h-5 text-white" />
                        )}
                    </div>
                    <div
                        className={`p-4 rounded-2xl shadow-sm hover:shadow-md transition-shadow duration-200 ${
                            isUser
                                ? 'rounded-tr-none'
                                : 'rounded-tl-none'
                        }`}
                        style={{
                            background: isUser
                                ? `linear-gradient(135deg, ${config.primaryColor}, ${config.secondaryColor})`
                                : 'white',
                            borderLeft: !isUser ? `3px solid ${config.secondaryColor}` : '',
                            borderRight: isUser ? `3px solid ${config.primaryColor}` : '',
                            maxWidth: 'calc(100% - 3rem)'
                        }}
                    >
                        <p
                            className={`text-sm leading-relaxed whitespace-pre-wrap break-words ${
                                isUser ? 'text-white' : 'text-gray-800'
                            }`}
                            dangerouslySetInnerHTML={{ __html: message.content }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const StarterCard = ({ starter, index }) => (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0, transition: { delay: index * 0.1 } }}
            whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
            whileTap={{ scale: 0.98 }}
            className="group relative bg-white p-4 rounded-xl cursor-pointer transition-all duration-200 border border-gray-100 hover:shadow-lg"
            onClick={() => handleSendMessage(starter.text)}
            style={{
                borderLeft: `3px solid ${config.secondaryColor}`,
                background: `linear-gradient(to right, white, ${config.primaryColor}05)`
            }}
        >
            <div className="flex items-center gap-4">
                <div
                    className="w-12 h-12 rounded-full flex items-center justify-center text-2xl"
                    style={{
                        background: `linear-gradient(135deg, ${config.primaryColor}15, ${config.secondaryColor}15)`,
                        color: config.primaryColor
                    }}
                >
                    {starter.icon}
                </div>
                <div className="flex-1">
                    <h3 className="font-semibold text-gray-800 group-hover:text-gray-900">
                        {starter.title}
                    </h3>
                    <p className="text-gray-600 text-sm mt-1 line-clamp-2">
                        {starter.text}
                    </p>
                </div>
                <div
                    className="w-8 h-8 rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    style={{ background: `linear-gradient(135deg, ${config.primaryColor}, ${config.secondaryColor})` }}
                >
                    <Send className="w-4 h-4 text-white" />
                </div>
            </div>
        </motion.div>
    );

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
                <div className="text-center">
                    <Loader2 className="w-10 h-10 animate-spin mx-auto mb-4" style={{ color: config?.primaryColor }} />
                    <p className="text-gray-600">Loading your experience...</p>
                </div>
            </div>
        );
    }

    if (!config) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
                <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full text-center">
                    <Info className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                    <h2 className="text-xl font-semibold text-gray-800 mb-2">Configuration Not Found</h2>
                    <p className="text-gray-600">
                        We couldn't find the configuration for this demo. Please check the URL and try again.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50">
            <header
                className="py-10 px-4 text-center mb-8 relative overflow-hidden"
                style={{
                    background: `linear-gradient(135deg, ${config.primaryColor}10, ${config.secondaryColor}10)`
                }}
            >
                <div className="relative z-10">
                    <h1
                        className="text-4xl font-bold mb-3 bg-clip-text text-transparent"
                        style={{
                            backgroundImage: `linear-gradient(135deg, ${config.primaryColor}, ${config.secondaryColor})`
                        }}
                    >
                        {config.headerTitle}
                    </h1>
                    <p className="text-gray-600 text-lg max-w-2xl mx-auto">
                        {config.description}
                    </p>
                </div>
            </header>

            <main className="flex-1 flex items-start justify-center p-4 pt-0">
                <div className="w-full max-w-4xl bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
                    <div
                        className="p-6 flex items-center gap-4"
                        style={{ background: `linear-gradient(to right, ${config.primaryColor}, ${config.secondaryColor})` }}
                    >
                        <div className="bg-white/20 p-3 rounded-xl">
                            <Bot className="w-6 h-6 text-white" />
                        </div>
                        <div>
                            <h2 className="text-2xl font-semibold text-white">{config.agentName}</h2>
                            <div className="flex items-center gap-2 text-white/90 text-sm mt-1">
                                <Circle className="w-2 h-2 fill-current text-green-400 animate-pulse" />
                                <span>Always ready to help</span>
                            </div>
                        </div>
                    </div>

                    <div
                        className="h-[600px] p-6 overflow-y-auto scroll-smooth"
                        ref={chatContainerRef}
                        style={{ scrollBehavior: 'smooth' }}
                    >
                        <div className="space-y-6">
                            {messages.map((message, index) => (
                                <MessageBubble
                                    key={index}
                                    message={message}
                                    isLast={index === messages.length - 1}
                                />
                            ))}
                        </div>

                        <AnimatePresence>
                            {showStarters && conversationStarters.length > 0 && (
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    className="mt-8 space-y-4"
                                >
                                    <div className="text-center mb-6">
                                        <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full" style={{
                                            background: `linear-gradient(135deg, ${config.primaryColor}15, ${config.secondaryColor}15)`
                                        }}>
                                            <Sparkles className="w-5 h-5" style={{ color: config.primaryColor }} />
                                            <span className="font-medium" style={{ color: config.primaryColor }}>
                                                Choose a topic to get started
                                            </span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 gap-4">
                                        {conversationStarters.map((starter, index) => (
                                            <StarterCard key={index} starter={starter} index={index} />
                                        ))}
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        {isTyping && (
                            <div className="mt-4 flex items-start gap-3">
                                <div
                                    className="w-10 h-10 rounded-full flex items-center justify-center"
                                    style={{
                                        background: `linear-gradient(135deg, ${config.secondaryColor}, ${config.primaryColor})`
                                    }}
                                >
                                    <Bot className="w-5 h-5 text-white" />
                                </div>
                                <div className="flex items-center gap-2 bg-gray-50 px-4 py-3 rounded-xl rounded-tl-none">
                                    <div className="flex gap-1">
                                        <motion.div
                                            animate={{
                                                scale: [1, 1.2, 1],
                                                opacity: [0.4, 1, 0.4]
                                            }}
                                            transition={{
                                                duration: 1,
                                                repeat: Infinity,
                                                delay: 0
                                            }}
                                            className="w-2 h-2 rounded-full"
                                            style={{ background: config.primaryColor }}
                                        />
                                        <motion.div
                                            animate={{
                                                scale: [1, 1.2, 1],
                                                opacity: [0.4, 1, 0.4]
                                            }}
                                            transition={{
                                                duration: 1,
                                                repeat: Infinity,
                                                delay: 0.2
                                            }}
                                            className="w-2 h-2 rounded-full"
                                            style={{ background: config.primaryColor }}
                                        />
                                        <motion.div
                                            animate={{
                                                scale: [1, 1.2, 1],
                                                opacity: [0.4, 1, 0.4]
                                            }}
                                            transition={{
                                                duration: 1,
                                                repeat: Infinity,
                                                delay: 0.4
                                            }}
                                            className="w-2 h-2 rounded-full"
                                            style={{ background: config.primaryColor }}
                                        />
                                    </div>
                                    <span className="text-sm text-gray-500">Typing...</span>
                                </div>
                            </div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>

                    <div className="p-4 border-t border-gray-100">
                        <div className="relative">
                            <input
                                type="text"
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSendMessage();
                                    }
                                }}
                                placeholder="Type your message..."
                                className="w-full px-4 py-3 pr-12 rounded-xl border border-gray-200 focus:outline-none focus:border-gray-300 transition-colors"
                                style={{
                                    borderLeft: `3px solid ${config.secondaryColor}`,
                                    background: `linear-gradient(to right, white, ${config.primaryColor}05)`
                                }}
                            />
                            <button
                                onClick={() => handleSendMessage()}
                                disabled={!inputMessage.trim() || isTyping}
                                className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-lg transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-100"
                            >
                                <Send
                                    className="w-5 h-5"
                                    style={{ color: inputMessage.trim() ? config.primaryColor : '#9CA3AF' }}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default DemoTemplate;