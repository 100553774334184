//example
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { ArrowRight, Circle, MessageCircle, Send, Sparkles } from 'lucide-react';

const OPENAI_API_KEY = 'sk-grUFafEOO1GG99wuY2_g6ED_Mta_ms4r44Cx6DaLKlT3BlbkFJoZW_Am0ahBfZh6sFBel5E9yFPgl4zcu3at4_xJEmcA';
const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';

emailjs.init("Gh379KNUpH6MT6AX2");

const GEMINI_API_KEY = 'AIzaSyCn_znKDE8OF2LHS1jpB1xWm6Mtn_GlQds';
const GEMINI_API_URL = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent';

const SARAH_CONTEXT = `You are Sarah, a customer service representative at Cykero, a leading European company in refurbished electronics. 
Key points to remember:
- Founded in 2020 in Berlin
- Operates in France (SAS) and Germany (GmbH)
- Offers 12-month warranty on B2C products
- Less than 2% return rate
- Saves approximately 1260 tonnes of CO2 annually
- Sells around 3500 devices per month
- Focus on sustainability and quality assurance
Format your responses without markdown. Use plain text and simple dashes for lists.
Be professional, friendly, and emphasize Cykero's commitment to sustainability and quality.
You are to pretend as if you have information on stock quantity and prices. Use appropriate estimations for now. Also note that our website is cykero.eu`;

const openaiApi = axios.create({
    baseURL: OPENAI_API_URL,
    headers: {
        'Authorization': `Bearer ${OPENAI_API_KEY}`,
        'Content-Type': 'application/json'
    }
});

const conversationStarters = [
    {
        icon: "💻",
        text: "What's your current stock of refurbished MacBooks?",
        category: "Products"
    },
    {
        icon: "🛡️",
        text: "Tell me about your warranty policy",
        category: "Support"
    },
    {
        icon: "✨",
        text: "How do you ensure device quality?",
        category: "Quality"
    },
    {
        icon: "🚚",
        text: "What's the average delivery time?",
        category: "Shipping"
    },
    {
        icon: "🏢",
        text: "Do you offer business solutions?",
        category: "Services"
    },
    {
        icon: "🌱",
        text: "Tell me about your sustainability impact",
        category: "Impact"
    }
];

function formatMessage(text) {
    // Remove markdown-style bold asterisks
    text = text.replace(/\*\*(.*?)\*\*/g, '$1');

    // Handle bullet points and numbered lists
    const lines = text.split('\n');
    let formattedLines = lines.map(line => {
        // Check for bullet points
        if (line.trim().startsWith('- ')) {
            return `<li class="ml-4 mb-2">${line.substring(2)}</li>`;
        }
        // Check for numbered lists
        if (/^\d+\.\s/.test(line)) {
            return `<li class="ml-4 mb-2">${line.substring(line.indexOf(' ') + 1)}</li>`;
        }
        return line;
    });

    // Wrap lists in ul/ol tags
    let inList = false;
    let formattedText = '';
    formattedLines.forEach(line => {
        if (line.includes('<li>') && !inList) {
            formattedText += '<ul class="list-disc my-2 space-y-1">';
            inList = true;
        } else if (!line.includes('<li>') && inList) {
            formattedText += '</ul>';
            inList = false;
        }
        formattedText += line + '\n';
    });
    if (inList) formattedText += '</ul>';

    return formattedText;
}

function CykeroChat() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isWaiting, setIsWaiting] = useState(false);
    const [isOnline, setIsOnline] = useState(true);
    const [showSuggestions, setShowSuggestions] = useState(true);
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    useEffect(() => {
        setMessages([{
            type: 'suggestions',
            sender: 'system'
        }, {
            text: "Hello! I'm Sarah from Cykero's customer service team. We're dedicated to providing sustainable electronics solutions across Europe. How can I assist you today with our refurbished devices?",
            sender: 'agent'
        }]);

        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
            sendConversationToEmail();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    const sendMessageToGemini = async (message) => {
        try {
            // Filter and format previous messages
            const formattedMessages = messages
                .filter(msg => msg.type !== 'suggestions')
                .map(msg => ({
                    role: msg.sender === 'user' ? 'user' : 'model',
                    parts: [{ text: msg.text || '' }]
                }));

            // Add the context as the first message
            formattedMessages.unshift({
                role: 'model',
                parts: [{ text: SARAH_CONTEXT }]
            });

            // Add the new user message
            formattedMessages.push({
                role: 'user',
                parts: [{ text: message }]
            });

            // Prepare the request body
            const requestBody = {
                contents: [{
                    role: 'user',
                    parts: [{
                        text: `${SARAH_CONTEXT}\n\nConversation history:\n${
                            formattedMessages
                                .slice(1) // Skip the context message since we're including it above
                                .map(msg => `${msg.role.toUpperCase()}: ${msg.parts[0].text}`)
                                .join('\n')
                        }`
                    }]
                }],
                generationConfig: {
                    maxOutputTokens: 150,
                    temperature: 0.7,
                    stopSequences: ["USER:"] // Prevent the model from generating user messages
                }
            };

            // Make the API call
            const response = await fetch(`${GEMINI_API_URL}?key=${GEMINI_API_KEY}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Gemini API error: ${response.status} - ${JSON.stringify(errorData)}`);
            }

            const data = await response.json();

            // Extract and validate the response
            const responseText = data.candidates?.[0]?.content?.parts?.[0]?.text;

            if (!responseText) {
                throw new Error('No valid response from Gemini API');
            }

            // Clean up any markdown or formatting that might have slipped through
            return responseText
                .trim()
                .replace(/```/g, '')
                .replace(/\*\*/g, '')
                .replace(/\*/g, '');

        } catch (error) {
            console.error('Error in sendMessageToGemini:', error);
            throw error;
        }
    };

// Error handling wrapper for production use
    const sendMessageWithRetry = async (message, maxRetries = 3) => {
        for (let attempt = 1; attempt <= maxRetries; attempt++) {
            try {
                return await sendMessageToGemini(message);
            } catch (error) {
                if (attempt === maxRetries) throw error;
                // Wait before retrying (exponential backoff)
                await new Promise(resolve => setTimeout(resolve, Math.min(1000 * Math.pow(2, attempt), 10000)));
            }
        }
    };

    const handleMessage = async (messageText) => {
        if (!messageText.trim() || isWaiting) return;

        const userMessage = { text: messageText, sender: 'user' };
        setMessages(prev => prev.filter(msg => msg.type !== 'suggestions').concat(userMessage));
        setInput('');
        setIsWaiting(true);
        setShowSuggestions(false);

        try {
            const reply = await sendMessageToGemini(messageText);
            const formattedReply = formatMessage(reply);
            const agentMessage = { text: formattedReply, sender: 'agent' };
            setMessages(prev => [...prev, agentMessage]);
        } catch (error) {
            console.error('Error sending message:', error);
            const errorMessage = {
                text: "I apologize for the technical difficulty. Please try refreshing the page or contact us at support@cykero.com",
                sender: 'agent'
            };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setIsWaiting(false);
            inputRef.current?.focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await handleMessage(input);
    };

    const handleSuggestionClick = async (suggestion) => {
        await handleMessage(suggestion.text);
    };

    const sendConversationToEmail = async () => {
        if (messages.length <= 1) return;

        const conversationText = messages
            .filter(msg => msg.type !== 'suggestions')
            .map(msg => `${msg.sender}: ${msg.text}`)
            .join('\n\n');

        const templateParams = {
            to_email: 'info@theta-ai.org',
            conversation: conversationText
        };

        try {
            await emailjs.send('service_kn47xhr', 'template_4ogxifc', templateParams);
        } catch (error) {
            console.error('Error sending conversation to email:', error);
        }
    };

    const renderMessage = (message, index) => {
        if (message.type === 'suggestions') {
            return (
                <div className="flex flex-col items-center my-8 px-4 w-full">
                    <div className="flex items-center space-x-2 mb-4">
                        <Sparkles className="w-5 h-5 text-emerald-600" />
                        <span className="text-emerald-800 font-medium">How can we help you today?</span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full max-w-2xl">
                        {conversationStarters.map((starter, idx) => (
                            <button
                                key={idx}
                                onClick={() => handleSuggestionClick(starter)}
                                className="group flex items-center space-x-3 w-full text-left px-4 py-3 rounded-xl bg-white border-2 border-emerald-100 hover:border-emerald-300 hover:bg-emerald-50 transition-all duration-200"
                                disabled={isWaiting}
                            >
                                <span className="text-2xl">{starter.icon}</span>
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-emerald-800 group-hover:text-emerald-600 transition-colors duration-200">
                                        {starter.text}
                                    </p>
                                    <span className="text-xs text-emerald-500">{starter.category}</span>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div
                key={index}
                className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
            >
                <div className={`flex items-end ${message.sender === 'user' ? 'flex-row-reverse' : 'flex-row'} space-x-2 max-w-[80%]`}>
                    <div className={`w-10 h-10 rounded-full flex items-center justify-center flex-shrink-0 ${
                        message.sender === 'user' ? 'bg-emerald-100 ml-2' : 'bg-emerald-700 mr-2'
                    }`}>
                        {message.sender === 'user' ? (
                            <svg className="w-6 h-6 text-emerald-700" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                        ) : (
                            <span className="text-white font-semibold">CS</span>
                        )}
                    </div>
                    <div className={`px-4 py-3 rounded-2xl shadow-md ${
                        message.sender === 'user'
                            ? 'bg-gradient-to-br from-emerald-600 to-emerald-700 text-white'
                            : 'bg-white border border-emerald-100'
                    }`}>
                        <div
                            className="text-base leading-relaxed"
                            dangerouslySetInnerHTML={{
                                __html: message.text
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-emerald-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
                {/* Header */}
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-emerald-900 mb-2">Cykero Customer Support</h1>
                    <p className="text-lg text-emerald-700">Sustainable Electronics, Exceptional Service</p>
                </div>

                {/* Chat Container */}
                <div className="bg-white rounded-2xl shadow-2xl overflow-hidden border border-emerald-100">
                    {/* Status Bar */}
                    <div className="bg-gradient-to-r from-emerald-600 to-emerald-700 p-4">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                                <div className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center">
                                    <span className="text-white font-semibold text-lg">CS</span>
                                </div>
                                <div>
                                    <span className="text-white font-medium block">Sarah</span>
                                    <span className="text-emerald-200 text-sm">Customer Service Specialist</span>
                                </div>
                            </div>
                            <div className="flex items-center space-x-2 bg-white/10 px-3 py-1 rounded-full">
                                <Circle className={`w-3 h-3 ${isOnline ? 'text-emerald-400' : 'text-gray-400'}`} fill="currentColor" />
                                <span className="text-white text-sm">{isOnline ? 'Online' : 'Offline'}</span>
                            </div>
                        </div>
                    </div>

                    {/* Messages Area */}
                    <div className="h-[600px] overflow-y-auto p-6 bg-gradient-to-b from-gray-50 to-white">
                        <div className="space-y-6">
                            {messages.map((message, index) => renderMessage(message, index))}
                            {isWaiting && (
                                <div className="flex justify-start items-center space-x-2 p-4">
                                    <div className="w-2 h-2 bg-emerald-600 rounded-full animate-bounce"></div>
                                    <div className="w-2 h-2 bg-emerald-600 rounded-full animate-bounce" style={{animationDelay: '0.2s'}}></div>
                                    <div className="w-2 h-2 bg-emerald-600 rounded-full animate-bounce" style={{animationDelay: '0.4s'}}></div>
                                </div>
                            )}
                            <div ref={messagesEndRef} />
                        </div>
                    </div>

                    {/* Input Area */}
                    <form onSubmit={handleSubmit} className="p-4 bg-white border-t border-emerald-100">
                        <div className="flex space-x-4">
                            <input
                                ref={inputRef}
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Ask about our refurbished devices..."
                                className="flex-1 px-4 py-3 border-2 border-emerald-100 rounded-xl focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all duration-200"
                                disabled={isWaiting}
                            />
                            <button
                                type="submit"
                                disabled={isWaiting || !input.trim()}
                                className={`px-6 py-3 rounded-xl flex items-center justify-center transition-all duration-200 ${
                                    isWaiting || !input.trim()
                                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                        : 'bg-gradient-to-r from-emerald-600 to-emerald-700 hover:from-emerald-700 hover:to-emerald-800 text-white shadow-md hover:shadow-lg'
                                }`}
                            >
                                <span className="mr-2">Send</span>
                                <Send className="w-5 h-5" />
                            </button>
                        </div>
                    </form>
                </div>

                {/* Footer */}
                <div className="mt-8 text-center">
                    <div className="flex flex-col items-center space-y-4">
                        <div className="flex items-center space-x-2 text-emerald-700">
                            <Circle className="w-2 h-2 fill-current" />
                            <span className="font-medium">Cykero GmbH - Sustainable Electronics Solutions</span>
                            <Circle className="w-2 h-2 fill-current" />
                        </div>
                        <div className="text-sm text-emerald-600">
                            <p>Berlin, Germany • Paris, France</p>
                        </div>
                        <div className="flex items-center space-x-4 text-xs text-emerald-500">
                            <span>© 2024 Cykero GmbH</span>
                            <span>•</span>
                            <span>Privacy Policy</span>
                            <span>•</span>
                            <span>Terms of Service</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CykeroChat;

