import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaEnvelope } from 'react-icons/fa';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitMessage('');

        try {
            const response = await fetch('https://formspree.io/f/mrbggeqb', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setSubmitMessage('Your message has been sent successfully!');
                setFormData({ name: '', email: '', message: '' });
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.error('Failed to send message:', error);
            setSubmitMessage('Failed to send message. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
    };

    return (
        <div className="contact bg-gradient-to-b from-gray-50 to-white py-20">
            <div className="container mx-auto px-4">
                <motion.h1
                    className="text-4xl md:text-5xl font-bold mb-8 text-center text-gray-800"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                >
                    Get in Touch
                </motion.h1>
                <motion.p
                    className="text-xl text-center text-gray-600 mb-12 max-w-2xl mx-auto"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                >
                    Have questions about our AI solutions? We're here to help. Reach out to us and let's discuss how we can transform your business.
                </motion.p>

                <div className="flex flex-wrap -mx-4">
                    <motion.div
                        className="w-full md:w-1/2 px-4 mb-8 md:mb-0"
                        initial="hidden"
                        animate="visible"
                        variants={fadeIn}
                    >
                        <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded-lg p-8">
                            <div className="mb-6">
                                <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
                                ></textarea>
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    type="submit"
                                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full transition duration-300"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Sending...' : 'Send Message'}
                                </button>
                            </div>
                            {submitMessage && (
                                <p className={`mt-4 text-center ${submitMessage.includes('successfully') ? 'text-green-600' : 'text-red-600'}`}>
                                    {submitMessage}
                                </p>
                            )}
                        </form>
                    </motion.div>

                    <motion.div
                        className="w-full md:w-1/2 px-4"
                        initial="hidden"
                        animate="visible"
                        variants={fadeIn}
                    >
                        <div className="bg-blue-600 text-white shadow-lg rounded-lg p-8 h-full">
                            <h2 className="text-2xl font-bold mb-6">Contact Information</h2>
                            <div className="mb-8">
                                <div className="flex items-center mb-4">
                                    <FaEnvelope className="mr-4 text-2xl" />
                                    <span>info@theta-ai.org</span>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default Contact;