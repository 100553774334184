import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaRobot, FaMicrophone, FaCalendarAlt, FaCogs, FaChartLine, FaCog } from 'react-icons/fa';

function Services() {
    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
    };

    const staggeredFadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
    };

    const services = [
        {
            icon: <FaRobot className="text-6xl mb-6 text-blue-600" />,
            title: "Intelligent Automation",
            description: "Streamline operations with our advanced AI-powered automation systems.",
            details: [
                "Process optimization and workflow automation",
                "Robotic Process Automation (RPA) integration",
                "Machine learning-driven adaptive processes",
                "Real-time performance monitoring"
            ]
        },
        {
            icon: <FaMicrophone className="text-6xl mb-6 text-blue-600" />,
            title: "Custom AI Development",
            description: "Tailor-made AI solutions for your unique business challenges.",
            details: [
                "Bespoke machine learning models",
                "Natural Language Processing solutions",
                "Computer vision systems",
                "Predictive analytics tools"
            ]
        },
        {
            icon: <FaCalendarAlt className="text-6xl mb-6 text-blue-600" />,
            title: "Seamless Integration",
            description: "Effortlessly incorporate AI into your existing infrastructure.",
            details: [
                "API-driven service integration",
                "Legacy system compatibility",
                "Scalable cloud solutions",
                "Continuous deployment pipelines"
            ]
        },
        {
            icon: <FaCogs className="text-6xl mb-6 text-blue-600" />,
            title: "Voice Assistants",
            description: "Implement voice-activated AI for seamless interactions.",
            details: [
                "Custom wake words and commands",
                "Smart device integration",
                "Voice biometrics security",
                "Multi-accent recognition"
            ]
        },
        {
            icon: <FaChartLine className="text-6xl mb-6 text-blue-600" />,
            title: "Predictive Analytics",
            description: "Make informed decisions with AI-powered data analysis.",
            details: [
                "Advanced forecasting algorithms",
                "Real-time data visualization",
                "Trend identification",
                "Anomaly detection"
            ]
        },
        {
            icon: <FaCog className="text-6xl mb-6 text-blue-600" />,
            title: "Process Automation",
            description: "Streamline workflows with intelligent automation.",
            details: [
                "Document processing",
                "Data entry automation",
                "Quality control systems",
                "Workflow optimization"
            ]
        }
    ];

    // Additional sections from the original home page
    const industries = [
        {
            title: "Dental Care",
            description: "Enhance patient experience and streamline operations.",
            applications: [
                "24/7 scheduling chatbots",
                "Automated reminders",
                "Voice-activated records",
                "AI treatment recommendations"
            ]
        },
        {
            title: "E-commerce",
            description: "Boost sales and improve customer experiences.",
            applications: [
                "Product recommendations",
                "Inventory management",
                "Customer service chatbots",
                "Voice order processing"
            ]
        },
        {
            title: "Cold Outreach",
            description: "Optimize campaigns and increase response rates.",
            applications: [
                "AI-generated messages",
                "Automated prospect lists",
                "Lead scoring",
                "Performance tracking"
            ]
        }
    ];

    const process = [
        {
            step: "1",
            title: "Consultation",
            description: "Understanding your needs",
            details: "In-depth analysis of your current processes and identification of AI opportunities."
        },
        {
            step: "2",
            title: "Strategy",
            description: "Creating your AI roadmap",
            details: "Comprehensive implementation plan aligned with your business objectives."
        },
        {
            step: "3",
            title: "Development",
            description: "Building your solution",
            details: "Agile development with regular testing and refinement."
        },
        {
            step: "4",
            title: "Integration",
            description: "Seamless implementation",
            details: "Smooth integration with existing systems and comprehensive training."
        }
    ];

    return (
        <div className="services">
            {/* Main Services Section */}
            <section className="bg-gradient-to-b from-gray-100 to-white py-24">
                <div className="container mx-auto px-4">
                    <motion.h1
                        className="text-5xl md:text-6xl font-bold mb-8 text-center text-gray-800"
                        initial="hidden"
                        animate="visible"
                        variants={fadeIn}
                    >
                        Our AI Solutions
                    </motion.h1>
                    <motion.p
                        className="text-xl text-center text-gray-600 mb-16 max-w-3xl mx-auto"
                        initial="hidden"
                        animate="visible"
                        variants={fadeIn}
                    >
                        Discover our comprehensive suite of AI-powered solutions designed to revolutionize your business operations,
                        enhance customer experiences, and drive sustainable growth.
                    </motion.p>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
                        {services.map((service, index) => (
                            <motion.div
                                key={index}
                                className="bg-white rounded-2xl shadow-xl p-8 text-center transform transition duration-500 hover:scale-105"
                                initial="hidden"
                                whileInView="visible"
                                variants={fadeIn}
                            >
                                <div className="bg-blue-100 rounded-full p-6 inline-block mb-6">
                                    {service.icon}
                                </div>
                                <h2 className="text-2xl font-bold mb-4 text-gray-800">{service.title}</h2>
                                <p className="text-gray-600 mb-6">{service.description}</p>
                                <ul className="text-left text-gray-700 space-y-2">
                                    {service.details.map((detail, idx) => (
                                        <li key={idx} className="flex items-start">
                                            <span className="text-blue-600 mr-2 mt-1">•</span> {detail}
                                        </li>
                                    ))}
                                </ul>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Industry Solutions Section */}
            <section className="py-20 bg-blue-900 text-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold mb-16 text-center">Transforming Industries</h2>
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-3 gap-10"
                        variants={staggeredFadeIn}
                        initial="hidden"
                        whileInView="visible"
                    >
                        {industries.map((industry, index) => (
                            <motion.div
                                key={index}
                                className="bg-blue-800 rounded-lg p-8"
                                variants={fadeIn}
                            >
                                <h3 className="text-2xl font-semibold mb-4">{industry.title}</h3>
                                <p className="mb-4">{industry.description}</p>
                                <ul className="list-disc list-inside">
                                    {industry.applications.map((app, idx) => (
                                        <li key={idx} className="mb-2">{app}</li>
                                    ))}
                                </ul>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </section>

            {/* Development Process Section */}
            <section className="py-20 bg-gray-100">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold mb-16 text-center text-gray-800">Our Development Process</h2>
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10"
                        variants={staggeredFadeIn}
                        initial="hidden"
                        whileInView="visible"
                    >
                        {process.map((step, index) => (
                            <motion.div
                                key={index}
                                className="bg-white rounded-lg p-8 text-center shadow-xl"
                                variants={fadeIn}
                            >
                                <div className="text-4xl font-bold text-blue-600 mb-4">Step {step.step}</div>
                                <h3 className="text-2xl font-semibold mb-4 text-gray-800">{step.title}</h3>
                                <p className="text-gray-600 mb-4">{step.description}</p>
                                <p className="text-gray-700 text-sm">{step.details}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </section>

            {/* Why Choose Us Section */}
            <section className="py-20 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold mb-16 text-center text-gray-800">Why Choose THETA AI?</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
                        {[
                            {
                                title: "Cutting-Edge Technology",
                                description: "Access to the latest AI advancements",
                                details: "Continuously updated algorithms and models"
                            },
                            {
                                title: "Industry Expertise",
                                description: "Deep understanding of your sector",
                                details: "Tailored solutions for specific challenges"
                            },
                            {
                                title: "Scalable Solutions",
                                description: "Growth-ready implementation",
                                details: "Flexible systems that evolve with you"
                            },
                            {
                                title: "Dedicated Support",
                                description: "Expert assistance always available",
                                details: "Continuous optimization and updates"
                            }
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-gray-100 rounded-lg p-8 text-center shadow-lg"
                                variants={fadeIn}
                                initial="hidden"
                                whileInView="visible"
                            >
                                <h3 className="text-2xl font-semibold mb-4 text-gray-800">{feature.title}</h3>
                                <p className="text-gray-600 mb-4">{feature.description}</p>
                                <p className="text-gray-700 text-sm">{feature.details}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="py-20 bg-blue-600 text-white">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-4xl font-bold mb-6">Ready to Transform Your Business?</h2>
                    <p className="text-xl mb-8 max-w-2xl mx-auto">
                        Our team of AI experts is ready to help you implement these cutting-edge solutions and drive your business forward.
                    </p>
                    <Link
                        to="/contact"
                        className="inline-block bg-white text-blue-600 px-10 py-4 rounded-full text-lg font-bold hover:bg-gray-100 transition duration-300"
                    >
                        Schedule a Consultation
                    </Link>
                </div>
            </section>
        </div>
    );
}

export default Services;