import React from 'react';
import { motion } from 'framer-motion';
import { FaRocket, FaLightbulb, FaUsers, FaChartLine } from 'react-icons/fa';

function About() {
    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
    };

    return (
        <div className="about bg-gradient-to-b from-gray-50 to-white">
            <div className="container mx-auto px-4 py-16">
                <motion.section
                    className="text-center mb-20"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                >
                    <h1 className="text-5xl font-extrabold mb-6 text-gray-800">
                        Pioneering the Future of <span className="text-blue-600">AI Solutions</span>
                    </h1>
                    <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                        At Theta AI, we're not just developing technology; we're shaping the future of business through cutting-edge artificial intelligence.
                    </p>
                </motion.section>

                <motion.section
                    className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-20"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                >
                    <div className="bg-white p-8 rounded-xl shadow-lg">
                        <h2 className="text-3xl font-bold mb-4 text-gray-800">Our Mission</h2>
                        <p className="text-gray-600 mb-4">
                            To empower businesses with AI-driven solutions that revolutionize operations, enhance decision-making, and unlock unprecedented growth opportunities.
                        </p>
                        <ul className="list-disc list-inside text-gray-600">
                            <li>Develop tailored AI solutions for diverse industries</li>
                            <li>Drive innovation through continuous research and development</li>
                            <li>Foster a culture of ethical AI practices and transparency</li>
                        </ul>
                    </div>
                    <div className="bg-blue-600 p-8 rounded-xl shadow-lg text-white">
                        <h2 className="text-3xl font-bold mb-4">Why Theta AI?</h2>
                        <p className="mb-4">
                            We combine deep technical expertise with a keen understanding of business challenges to deliver AI solutions that make a real impact.
                        </p>
                        <ul className="list-disc list-inside">
                            <li>State-of-the-art AI algorithms and machine learning models</li>
                            <li>Customized solutions tailored to your specific needs</li>
                            <li>Dedicated support and continuous optimization</li>
                        </ul>
                    </div>
                </motion.section>

                <motion.section
                    className="mb-20"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                >
                    <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Our Core Values</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {[
                            { icon: <FaRocket className="text-5xl mb-4 text-blue-600" />, title: "Innovation", description: "Pushing the boundaries of what's possible with AI" },
                            { icon: <FaLightbulb className="text-5xl mb-4 text-blue-600" />, title: "Excellence", description: "Committed to delivering superior AI solutions" },
                            { icon: <FaUsers className="text-5xl mb-4 text-blue-600" />, title: "Collaboration", description: "Partnering closely with clients for shared success" },
                            { icon: <FaChartLine className="text-5xl mb-4 text-blue-600" />, title: "Impact", description: "Focused on creating tangible business outcomes" }
                        ].map((value, index) => (
                            <div key={index} className="bg-white p-6 rounded-xl shadow-md text-center">
                                {value.icon}
                                <h3 className="text-xl font-semibold mb-2 text-gray-800">{value.title}</h3>
                                <p className="text-gray-600">{value.description}</p>
                            </div>
                        ))}
                    </div>
                </motion.section>

                <motion.section
                    className="text-center bg-gray-100 p-12 rounded-xl"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                >
                    <h2 className="text-3xl font-bold mb-6 text-gray-800">Ready to Transform Your Business?</h2>
                    <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
                        Let's explore how Theta AI can help you leverage the power of artificial intelligence to drive growth, efficiency, and innovation in your organization.
                    </p>
                    <a href="/contact" className="inline-block bg-blue-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300">
                        Schedule a Consultation
                    </a>
                </motion.section>
            </div>
        </div>
    );
}

export default About;