// src/config/appwrite.js
import { Client, Account, Databases } from 'appwrite';

const client = new Client();

client.setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT)
client.setProject(process.env.REACT_APP_APPWRITE_PROJECT_ID);

export const account = new Account(client);
export const databases = new Databases(client);

export const DEMO_DATABASE_ID = process.env.REACT_APP_DATABASE_ID;
export const DEMO_COLLECTION_ID = process.env.REACT_APP_COLLECTION_ID;